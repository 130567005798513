import {TypeTagDto, TypeTagEntity} from "./tag.type";
import {useParseData} from "../../../utils/parse.utils";
import {Logger} from '../../../utils/logger.utils'


export const useTag = () => {
  const {generateDefaultFieldDirectus, getTransItemMulti} = useParseData();
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);
  const generateTagDto = (item: TypeTagEntity, languageCode: string): TypeTagDto | undefined => {

    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        title: getTransItemMulti(item, languageCode, "title"),
      }
      delete output["translations"];
      return output;
    } catch (e: any) {
      logger.error("ERR generateTagDto : ", e.message)
      return {}
    }
  }

  return {
    generateTagDto,
    logger
  }
}
