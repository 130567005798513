import {typeQueryOptions} from "../../../shared/base.query";

export const queryIZNewsCategories = (options?: typeQueryOptions) => {
  let output = {
    collection: "news_category",
    params: {
      fields: ["*", "translations.*", 'translations.seo_detail.*',],
      filter: {
        status: {
          _eq: "published"
        }
      },
      sort: ["sort", "date_created"],
      page: 1,
      limit: -1
    }
  }

  if (options) {
    output.params = {
      ...output.params,
      ...options
    }
  }
  return output
}


export const queryIZNewsWidgets = (options?: typeQueryOptions) => {

  let output = {
    collection: "news",
    params: {
      fields: [
        "id",
        "date_created",
        "status",
        "platform",
        "on_top",
        "hero",
        "tags.tags_id.id",
        "tags.tags_id.status",
        "tags.tags_id.translations.languages_code",
        "tags.tags_id.translations.title",
        "category.id",
        "category.status",
        "category.show",
        "category.display_mode",
        "category.show_subscription",
        "category.translations.id",
        "category.translations.languages_code",
        "category.translations.title",
        "category.translations.slug",
        "category.translations.intro",
        "translations.id",
        "translations.languages_code",
        "translations.thumbnail.id",
        "translations.thumbnail.title",
        "translations.thumbnail.description",
        "translations.title",
        "translations.slug",
        "translations.intro"
      ],
      filter: {
        status: {
          _eq: "published"
        }
      },
      sort: ["-date_created"],
      page: 1,
      limit: -1
    }
  }

  if (options) {
    output.params = {
      ...output.params,
      ...options
    }
  }
  return output
}