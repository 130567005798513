import {consola} from "consola";
import {useGenerateUrl} from "../../../utils/url.utils";
import {useParseData} from "../../../utils/parse.utils";
import {TypeNewsCategoryDto, TypeNewsDto} from "./news.type";
import {useTag} from "../tag/tag.utils";
import {Logger} from '../../../utils/logger.utils'

export const useIZNewsUtils = (globalSettings: any) => {

  const {generateDefaultFieldDirectus, getTransItemMulti, getTransImage} = useParseData();
  const {generateUrlByNameRoute, generateLangSwitch} = useGenerateUrl(globalSettings);
  const {generateTagDto} = useTag();
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);
  const generateNewsDto = (item: any, langCode: string): TypeNewsDto => {

    try {
      const output = {
        ...generateDefaultFieldDirectus(item as any),
        thumbnail: getTransImage(item, langCode, "thumbnail"),
        title: getTransItemMulti(item, langCode, "title"),
        slug: getTransItemMulti(item, langCode, "slug"),
        intro: getTransItemMulti(item, langCode, "intro"),
        content: getTransItemMulti(item, langCode, "content"),
        seo_detail: getTransItemMulti(item, langCode, 'seo_detail'),
        url: generateUrlByNameRoute("news_detail", langCode, {
          id: item.id,
          category: getTransItemMulti(item.category, langCode, "slug"),
          slug: getTransItemMulti(item, langCode, "slug")
        }),
        translations: {
          title: getTransItemMulti(item, langCode, "title"),
          slug: getTransItemMulti(item, langCode, "slug"),
          intro: getTransItemMulti(item, langCode, "intro"),
        }
      };

      if (item.category) {
        output["category"] = typeof item.category === "object" ? generateNewsCategoryDto(item.category, langCode) : item.category;
      }

      if (item.tags && Array.isArray(item.tags) && item.tags[0]) {
        output["tags"] = item.tags.map((ite: any) => generateTagDto(ite, langCode));

      }

      output["lang_switch"] = generateLangSwitch("news_detail", langCode, item);
      // delete output["translations"];
      return output;

    } catch (e) {
      logger.error("Error: generateNewsDto: ", e);
      consola.error(e);
      return {};
    }
  }

  const generateNewsCategoryDto = (item: any, langCode: string): TypeNewsCategoryDto => {
    try {
      const output = {
        ...item,
        ...generateDefaultFieldDirectus(item as any),
        title: getTransItemMulti(item, langCode, "title"),
        slug: getTransItemMulti(item, langCode, "slug"),
        intro: getTransItemMulti(item, langCode, "intro"),
        seo_detail: getTransItemMulti(item, langCode, 'seo_detail'),
        url: generateUrlByNameRoute("news_category", langCode, {
          id: item.id,
          slug: getTransItemMulti(item, langCode, "slug")
        }),
        translations: {
          title: getTransItemMulti(item, langCode, "title"),
          slug: getTransItemMulti(item, langCode, "slug"),
          intro: getTransItemMulti(item, langCode, "intro"),
        }
      };

      output["lang_switch"] = generateLangSwitch("news_category", langCode, item);
      delete output["translations"];
      return output;
    } catch (e) {
      logger.error("Error: generateNewsCategoryDto!", e);
      return {};
    }
  }

  return {
    generateNewsDto,
    generateNewsCategoryDto,
    logger
  }
}
